import { useTranslate } from "@tolgee/react"
import {
    categoryToColor,
    getRiskCategoryCoding,
    RISK_BOUNDS,
} from "../riskOutlookUtils"
import { IRiskOutlookOverviewHazardModel } from "../../../../types"
import LoadingAnimation from "../../../../climateui/components/LoadingAnimation"
import { IRiskCountryOverviewModel } from "../types"
import { capitalizeFirstCharacterOfEachWord } from "../../../../utils/wordHelper"

const RiskOverviewScoreDisplay = (props: {
    data: IRiskCountryOverviewModel[]
    loading: boolean
    yProperty: string
    title: string
}) => {
    const { t } = useTranslate()
    const { data, loading, yProperty, title = "Outlook conditions" } = props

    if (loading) {
        return (
            <div className="flex justify-center mt-5 body-lg">
                <LoadingAnimation />
            </div>
        )
    }

    const hazardModels: IRiskOutlookOverviewHazardModel[] = []

    data[0].default_geography.hazards.forEach((hazard) => {
        // TODO: Plot typings
        const score =
            hazard.risk_outlook_seasonal_stats.plot[0].lastValue[
                yProperty as "y" | "y1"
            ]
        const risk_bounds = RISK_BOUNDS
        const { hazard_name, hazard_key } = hazard
        const category = getRiskCategoryCoding(score, risk_bounds)

        hazardModels.push({
            score,
            hazard_name,
            hazard_key,
            category,
        })
    })

    const highestRiskModel = hazardModels.sort((a, b) => b.score - a.score)[0]
    const drivenBy = hazardModels.filter(
        (hm) => highestRiskModel.category === hm.category
    )

    return (
        <>
            <div
                className="flex flex-col justify-between"
                style={{ height: "100%" }}>
                <div className="label-lg">{title}</div>
                <div
                    className={`text-${
                        categoryToColor[highestRiskModel.category]
                    } flex flex-1 items-center justify-center`}>
                    <div
                        className={`bg-${
                            categoryToColor[highestRiskModel.category]
                        } h-3 rounded-full w-3 mr-1`}></div>
                    <div className="title-lg text-center">
                        {t(highestRiskModel.category)}
                    </div>
                </div>
                {highestRiskModel.category != "lowRisk" && (
                    <div className="w-full">
                        Driven by{" "}
                        {drivenBy.map((item, index) => (
                            <>
                                <span
                                    key={"overview-" + index}
                                    className={`text-${
                                        categoryToColor[
                                            highestRiskModel.category
                                        ]
                                    } font-bold `}>
                                    {t(
                                        item.hazard_key,
                                        capitalizeFirstCharacterOfEachWord(
                                            item.hazard_name
                                        )
                                    )}
                                </span>
                                {index < drivenBy.length - 1 ? ", " : ""}
                                {index === drivenBy.length - 2 ? " and " : ""}
                            </>
                        ))}
                    </div>
                )}
            </div>
        </>
    )
}

export default RiskOverviewScoreDisplay
