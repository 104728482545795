import React from "react"
import { Customized } from "recharts"
import { Stage } from "../types"
import { DateTime } from "luxon"
import { getStageConfig } from "../riskOutlookUtils"

interface YieldModelStage {
    id: string
    model_id: string
    stage_name: string
    start_date: string
    end_date: string
}

export function convertYearAgnosticDatesToAbsoluteDates(stages: Stage[]) {
    const now = DateTime.now().startOf("day")

    return stages
        .map((stage) => {
            let startDate = DateTime.fromFormat(stage.start_date, "MM-dd")
                .startOf("day")
                .set({ year: now.year })
            let endDate = DateTime.fromFormat(stage.end_date, "MM-dd")
                .startOf("day")
                .set({ year: now.year })

            if (startDate > endDate) {
                // Stage crosses year boundary (e.g. Dec 15 - Jan 15)
                if (now >= startDate || now <= endDate) {
                    // Current date is within the stage, so start date should be previous year
                    startDate = startDate.minus({ years: 1 })
                } else {
                    // Current date is outside stage, so end date should be next year
                    endDate = endDate.plus({ years: 1 })
                }
            } else if (startDate < now && endDate < now) {
                // Stage is entirely in the past, move to next occurrence
                startDate = startDate.plus({ years: 1 })
                endDate = endDate.plus({ years: 1 })
            }
            return {
                ...stage,
                startDate: startDate,
                endDate: endDate,
            }
        })
        .sort((a, b) => {
            if (a.startDate < b.startDate) return -1
            else if (a.startDate > b.startDate) return 1
            else return 0
        })
}

const renderRiskChartStages = (stages: Stage[]) => {
    const contextualizedStages = convertYearAgnosticDatesToAbsoluteDates(stages)

    return (
        <Customized
            component={({ xAxisMap, yAxisMap }: any) => {
                const xMin = xAxisMap[0].domain[0]
                const xMax = xAxisMap[0].domain[xAxisMap[0].domain.length - 1]

                const xMinDate = DateTime.fromFormat(xMin, "dd-MM-yyyy")
                const xMaxDate = DateTime.fromFormat(xMax, "dd-MM-yyyy")

                const filteredStages = contextualizedStages.filter((stage) => {
                    return (
                        (stage.startDate <= xMaxDate &&
                            stage.endDate >= xMinDate) ||
                        (stage.startDate >= xMinDate &&
                            stage.startDate <= xMaxDate) ||
                        (stage.endDate >= xMinDate && stage.endDate <= xMaxDate)
                    )
                })

                const cleanedStages = filteredStages.map((stage) => ({
                    ...stage,
                    startDate:
                        stage.startDate < xMinDate ? xMinDate : stage.startDate,
                    endDate:
                        stage.endDate > xMaxDate ? xMaxDate : stage.endDate,
                }))

                return (
                    <g>
                        {cleanedStages.map((stage, index) => {
                            const startX = xAxisMap[0].scale(
                                stage.startDate.toFormat("dd-MM-yyyy")
                            )
                            const endX = xAxisMap[0].scale(
                                stage.endDate.toFormat("dd-MM-yyyy")
                            )
                            const y = yAxisMap[0].scale(0)

                            return (
                                <g key={stage.stage_name}>
                                    <line
                                        x1={startX}
                                        x2={endX}
                                        y1={y + 50}
                                        y2={y + 50}
                                        stroke={
                                            getStageConfig(stage.stage_name)
                                                ?.color
                                        }
                                        strokeWidth={4}
                                    />
                                    <text
                                        x={(startX + endX) / 2}
                                        y={y + 70}
                                        textAnchor="middle"
                                        fill={"#1A232F"}
                                        fontSize="12">
                                        {
                                            getStageConfig(stage.stage_name)
                                                ?.label
                                        }
                                    </text>
                                </g>
                            )
                        })}
                    </g>
                )
            }}
        />
    )
}

export default renderRiskChartStages
