// import { useTranslate } from "@tolgee/react"
import RiskItem from "./RiskItem"
import { getRiskCategoryCoding, RISK_BOUNDS } from "../riskOutlookUtils"
import { IRiskOutlookOverviewHazardModel } from "../../../../types"
import LoadingAnimation from "../../../../climateui/components/LoadingAnimation"
import { IRiskCountryOverviewModel } from "../types"

const RiskScoreDisplay = (props: {
    data: IRiskCountryOverviewModel[]
    loading: boolean
    yProperty: string
    title: string
}) => {
    // const { t } = useTranslate()
    const { data, loading, yProperty, title = "Overall Risk" } = props

    if (loading) {
        return (
            <div className="flex justify-center mt-5 body-lg">
                <LoadingAnimation />
            </div>
        )
    }

    const hazardModels: IRiskOutlookOverviewHazardModel[] = []

    data[0].default_geography.hazards.forEach((hazard) => {
        // TODO: Plot typings
        const score =
            hazard.risk_outlook_seasonal_stats.plot[0].lastValue[
                yProperty as "y" | "y1"
            ]
        const risk_bounds = RISK_BOUNDS
        const { hazard_name, hazard_key } = hazard
        const category = getRiskCategoryCoding(score, risk_bounds)

        hazardModels.push({
            score,
            hazard_name,
            hazard_key,
            category,
        })
    })

    return (
        <>
            <div className="flex flex-col mb-4">
                <div className="label-lg">{title}</div>
            </div>
            <div className="flex flex-col items-center justify-center m-auto p-[4px] w-full px-8 gap-[4px]">
                {
                    // Show only the remaining hazards, slice the first one as it is the highest
                    Object.values(hazardModels)
                        .sort((a, b) => b.score - a.score)
                        .map((item, index) => (
                            <RiskItem
                                key={index}
                                hazard_name={item.hazard_name}
                                hazard_key={item.hazard_key}
                                score={item.score}
                                category={
                                    item.category as
                                        | "highRisk"
                                        | "mediumRisk"
                                        | "lowRisk"
                                }
                            />
                        ))
                }
            </div>
        </>
    )
}

export default RiskScoreDisplay
